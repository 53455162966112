/**
 * @generated SignedSource<<0d9bef993f9e59c1923368777739433b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EdsJobSync = "EDS_JOB_SYNC_CUSTOM_ATTRIBUTES" | "EDS_JOB_SYNC_CUSTOM_EVENTS" | "EDS_JOB_SYNC_ECOMMERCE_ADD_TO_CARTS" | "EDS_JOB_SYNC_ECOMMERCE_PRODUCT_VIEWS" | "EDS_JOB_SYNC_ECOMMERCE_PURCHASES" | "EDS_JOB_SYNC_MOBILE_WALLET" | "EDS_JOB_SYNC_PRODUCT_CATALOG" | "EDS_JOB_SYNC_SUBSCRIBER_OPT_INS" | "EDS_JOB_SYNC_SUBSCRIBER_OPT_OUTS" | "EDS_JOB_SYNC_TEXT_SEND" | "EDS_JOB_SYNC_TEXT_SEND_BULK" | "EDS_JOB_SYNC_UNKNOWN" | "EDS_JOB_SYNC_USER_IDENTIFIERS" | "%future added value";
export type EdsJobSyncMethod = "EDS_JOB_SYNC_METHOD_AMAZON_S3" | "EDS_JOB_SYNC_METHOD_ATTENTIVE_SFTP" | "EDS_JOB_SYNC_METHOD_CLIENT_HOSTED_SFTP" | "EDS_JOB_SYNC_METHOD_MANUAL_UPLOAD" | "EDS_JOB_SYNC_METHOD_S3" | "EDS_JOB_SYNC_METHOD_SFTP" | "EDS_JOB_SYNC_METHOD_UNKNOWN" | "%future added value";
export type SyncStatus = "SYNC_STATUS_ACTIVE" | "SYNC_STATUS_INACTIVE" | "SYNC_STATUS_UNKNOWN" | "%future added value";
export type SyncDetail_EDSLib_Query$variables = {
  syncId: string;
};
export type SyncDetail_EDSLib_Query$data = {
  readonly sync: {
    readonly name?: string;
    readonly schedule?: {
      readonly " $fragmentSpreads": FragmentRefs<"label_getScheduleLabel_EDSLib_SyncSchedule">;
    };
    readonly status?: SyncStatus;
    readonly syncId?: string;
    readonly syncMethod?: EdsJobSyncMethod;
    readonly syncType?: EdsJobSync;
    readonly " $fragmentSpreads": FragmentRefs<"AttributeMapping_EDSLib_Sync">;
  } | null;
};
export type SyncDetail_EDSLib_Query$rawResponse = {
  readonly sync: {
    readonly __typename: "Sync";
    readonly id: string;
    readonly name: string;
    readonly schedule: {
      readonly schedule: {
        readonly __typename: "Cron";
        readonly cronString: string;
      } | {
        readonly __typename: "OneTime";
        readonly runsImmediately: boolean;
      } | {
        readonly __typename: string;
      } | null;
    };
    readonly status: SyncStatus;
    readonly syncId: string;
    readonly syncMethod: EdsJobSyncMethod;
    readonly syncType: EdsJobSync;
    readonly targetConfig: any;
  } | {
    readonly __typename: string;
    readonly id: string;
  } | null;
};
export type SyncDetail_EDSLib_Query = {
  rawResponse: SyncDetail_EDSLib_Query$rawResponse;
  response: SyncDetail_EDSLib_Query$data;
  variables: SyncDetail_EDSLib_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "syncId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "syncId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "syncId",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "syncType",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "syncMethod",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v8 = [
  {
    "alias": null,
    "args": null,
    "concreteType": null,
    "kind": "LinkedField",
    "name": "schedule",
    "plural": false,
    "selections": [
      (v7/*: any*/),
      {
        "kind": "InlineFragment",
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "cronString",
            "storageKey": null
          }
        ],
        "type": "Cron",
        "abstractKey": null
      },
      {
        "kind": "InlineFragment",
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "runsImmediately",
            "storageKey": null
          }
        ],
        "type": "OneTime",
        "abstractKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SyncDetail_EDSLib_Query",
    "selections": [
      {
        "alias": "sync",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "AttributeMapping_EDSLib_Sync"
              },
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "SyncSchedule",
                "kind": "LinkedField",
                "name": "schedule",
                "plural": false,
                "selections": [
                  {
                    "kind": "InlineDataFragmentSpread",
                    "name": "label_getScheduleLabel_EDSLib_SyncSchedule",
                    "selections": (v8/*: any*/),
                    "args": null,
                    "argumentDefinitions": []
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "Sync",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SyncDetail_EDSLib_Query",
    "selections": [
      {
        "alias": "sync",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v7/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "targetConfig",
                "storageKey": null
              },
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "SyncSchedule",
                "kind": "LinkedField",
                "name": "schedule",
                "plural": false,
                "selections": (v8/*: any*/),
                "storageKey": null
              }
            ],
            "type": "Sync",
            "abstractKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "59ea74d4da6521311b2d860841249422",
    "id": null,
    "metadata": {},
    "name": "SyncDetail_EDSLib_Query",
    "operationKind": "query",
    "text": "query SyncDetail_EDSLib_Query(\n  $syncId: ID!\n) {\n  sync: node(id: $syncId) {\n    __typename\n    ... on Sync {\n      ...AttributeMapping_EDSLib_Sync\n      name\n      syncId\n      syncType\n      syncMethod\n      status\n      schedule {\n        ...label_getScheduleLabel_EDSLib_SyncSchedule\n      }\n    }\n    id\n  }\n}\n\nfragment AttributeMappingTable_EDSLib_Sync on Sync {\n  targetConfig\n}\n\nfragment AttributeMapping_EDSLib_Sync on Sync {\n  ...AttributeMappingTable_EDSLib_Sync\n}\n\nfragment label_getScheduleLabel_EDSLib_SyncSchedule on SyncSchedule {\n  schedule {\n    __typename\n    ... on Cron {\n      cronString\n    }\n    ... on OneTime {\n      runsImmediately\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "bbe5753372a62d08848910b9fe5a316f";

export default node;
